import React, { useMemo } from 'react';
import { Slider as AntSlider } from 'antd';
import './style.css';

const CustomSlider = ({ defaultValue, min, max, onChange, step, value }) => {
  const generateMarks = (step, max) => {
    const marks = {};
    for (let i = 0; i <= max; i += step) {
      if (i % step === 0) {
        marks[i] = {
          label: <strong style={{ display: 'none' }}>{`${i.toLocaleString()}`}</strong>,
        };
      }
    }
    return marks;
  };

  const totalMarks = useMemo(() => generateMarks(step, max), [step, max]);

  return (
    <div className="" style={{ width: "80%" }}>
      <AntSlider
        marks={totalMarks && totalMarks}
        className="custom-slider"
        defaultValue={defaultValue}
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        tooltip={{ formatter: (value) => `$${value} ` }} // Show only the number
      />
    </div>
  );
};

export default CustomSlider;
