import React, { useEffect } from 'react';
import '../../../src/stylesheet.css'
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
    const navigate = useNavigate();
    console.log("navbar")
  
    return (
        <nav class="navbar navbar-expand-lg main_navbar fixed-top">
        <div class="container">
          <a class="navbar-brand" href="home.html"><img class="header-logo" src="./assests/images/logo.png" alt=""/></a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse justify-content-end" id="navbarText">
          <ul className="navbar-nav nave_item">
                        <li className="nav-item">
                            <a className="nav-link" aria-current="page" href="home.html">Home</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="home.html#How_it_works">How it works</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="Blogs.html">Resources</a>
                        </li>
                        <li className="nav-item" onClick={() => navigate('/saving-estimator')}>
                            <a className="nav-link active">Saving Estimator</a>
                        </li>
                        <li className="nav-item">
                            <a className="btn btn_nav" href="/contactUs.html">Contact</a>
                        </li>
                    </ul>
         </div>
        </div>
  </nav>
       
    );
};

export default React.memo(Navbar);
