// LoadingOverlay.js
import React from 'react';
import './style.css'; // Assuming you will style it separately

const LoadingOverlay = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className="loading-overlay">
      <div className="spinner"></div>
    </div>
  );
};

export default LoadingOverlay;
