import logo from "./logo.svg";
import "./App.css";
import Form from "./Components/Form";
import SavingEst from './Components/SavingEst'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RedirectToHtml from "./Components/Home";

const reload = () => window.location.reload();


function App() {
  return (
    <BrowserRouter>
     <Routes>
      <Route path="/">
      
          <Route path="/form" element={<Form />}/>
          <Route path="/saving-estimator" element={<SavingEst />}/>

          <Route path="/"  element={<div><RedirectToHtml /></div>}/>
          
          
       
      </Route>
    </Routes>
    </BrowserRouter>
  
  );
}

export default App;
