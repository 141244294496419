import './style.css';

const ContactUs = () => {
    return (
        <section className="contact-us-section">
            <div className="container">
                <div className="row justify-content-center text-center">
                    <div className="col-md-8 middle_section shadow-sm">
                        <h2 className="contact-title">CONTACT US</h2>
                        <p className="contact-text">Want to see how we can save you money, without switching processors?</p>
                        <p className="contact-call-to-action">Contact us today for a free analysis!</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactUs;